/*eslint-disable*/
import React from "react";
import 'aos/dist/aos.css';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  
  return (
    <>
      <footer className="footer" style={{ fontFamily: "Nucleo Outline" }}>
        <br/><hr/><br/>
        <Container >
          <div className="content text-center">
            <Row>
              <Col md="2" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Menü</b></h1>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                
                    >
                      Ana Sayfa
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/hakkimizda"
                     
                    >
                      Hakkımızda
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/blog"

                      
                    >
                      Blog Paylaşımları
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/resimler"
                      
                    >
                      Resimler
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="videolar"
                      
                    >
                      Videolar
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/iletisim"
                      
                    >
                      İletişim
                    </a>
                  </li>
                  
                  <li>
                    <a
                      className="text-muted"
                      href="https://www.doktortakvimi.com/klinikler/ante-saglik"
                      
                    >
                      Online Randevu
                    </a>
                  </li>
                  
                </ul>
              </Col>
              <Col md="2" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Hizmetlerimiz</b></h1>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/fizik-tedavi-ve-rehabilitasyon"
                      
                    >
                      Fizik Tedavi ve Rehabilitasyon
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/beslenme-ve-diyetetik"
                     
                    >
                      Beslenme ve Diyetetik
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/psikolojik-danismanlik"
                     
                    >
                      Psikolojik Danışmanlık
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/teknolojik-cihazlar-ile-destek"
                     
                    >
                      Teknolojik Cihazlarla Destek
                    </a>
                  </li>
                  
                </ul>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Adres</b></h1>
                <div className="social-feed">
                  <div className="feed-line">
                    
                    <p>
                    Yahya Kaptan Mh. Şehit Ali İhsan Çakmak Sok No:10 Kat: 2, 41040 İzmit/Kocaeli
                    </p>
                  </div>
                  <div className="feed-line">
                  <iframe title="ossaClinicAdres" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.707241530781!2d29.96559727554581!3d40.76846333415781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb4fe137de965b%3A0x48c87ec869c185c4!2sANTE%20SA%C4%9ELIK%20Ossa%20Clinic%20Fizyoterapi%20ve%20Sa%C4%9Fl%C4%B1kl%C4%B1%20Ya%C5%9Fam%20Merkezi!5e0!3m2!1sen!2str!4v1718946676004!5m2!1sen!2str" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Bizi Takip Edin</b></h1>
                <ul className="social-buttons">
                  <li>
                  <Button
                      className="btn-icon btn-neutral mx-3"
                      color="instagram"
                      target="_blank"
                      href="https://www.instagram.com/ossaclinic/"
                    >
                      <img src={require("../../assets/ossaPictures/instagram.png")} alt="instagram" />
                      <span style={{ display: "none" }}>
                        Instgram Ossa Clinic
                      </span>
                    </Button>
                  </li>
                  
                  <li>
                  <Button
                      className="btn-icon btn-neutral mx-3"
                      color="facebook"
                      target="_blank"
                      href="https://www.facebook.com/ossaclinic"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="facebook"><path fill="#1976D2" d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2z"></path><path fill="#FAFAFA" fill-rule="evenodd" d="M13.5 8H11V6c0-.552.448-.5 1-.5h1V3h-2a3 3 0 0 0-3 3v2H6v2.5h2V16h3v-5.5h1.5l1-2.5z" clip-rule="evenodd"></path></svg>
                      <span style={{ display: "none" }}>
                        Facebook Ossa Clinic
                      </span>
                    </Button>
                  </li>
                </ul>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>E-mail</u></b></small>
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                info@antesaglik.com
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>Telefon</u></b></small>
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                + 90 530 898 41 14
                </h1>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Powered by{" "}<a className="text-dark" href="/">Sisprime Digital Solutions</a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
