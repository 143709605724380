
import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React, { useEffect, useRef } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import { FaCheck } from "react-icons/fa";
import Footer from 'components/Footers/Footer';
import { HashLink } from 'react-router-hash-link';
import PictureHeader from 'components/PictureHeader/PictureHeader';
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.png"

const AboutUs = () => {
  document.body.classList.add("sidebar-collapse");
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
  
    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector('.gt-current-lang img');
      const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement)
      imgElement.src = newImgSrc;
      imgElement.width="32"
        imgElement.height="32"
    }
  }, []);
  const headerRef = useRef(null);
  return (
    <div ref={headerRef}>
    <Helmet>
      <title>HAKKIMIZDA | OSSA CLINIC</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="OSSA CLINIC"
        data-rh="true"
      />
      <link rel="canonical" href='https://www.ossaclinic.com/hakkimizda' />
      <meta property="og:title" content="HAKKIMIZDA | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com/hakkimizda" />
    </Helmet>
    <WhiteNavbar navbarSelection={"about-us"} />
    <PictureHeader  imageHeader={imageHeader}/>
    <div className="projects-5" >
            <Container style={{ fontFamily: "Nucleo Outline" }}>
  
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/about-us/1.jpg") + ")"
                        ,
                        height: "57rem",
                        alignContent: "center"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Ossa Clinic, rahatsızlıklarınızı doğru şekilde tespit eder ve en uygun tedavi çözümlerini sunar.</CardTitle>
                      <p className="card-description">
                      %100 memnuniyet garantisi
                      </p>
                      <Badge className="badge-neutral">Ossa Clinic Tech &trade;</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto mt-5 mt-md-3" md="5" data-aos="zoom-in" data-aos-duration="2000" style={{alignSelf:"center"}}>
                  <div className="info info-horizontal">
                  <h3 style={{display:"none"}}>Ossa Clinic, rahatsızlıklarınızı doğru şekilde tespit eder ve en uygun tedavi çözümlerini sunar.</h3>
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                      <b>Vizyonumuz</b>
                      </h4>
                      <p className="description">
                      Ossa Clinic olarak, Kocaeli'nin kalbinde hizmet veren lider bir fizik tedavi merkezi olarak, sağlık sektöründe dünya çapında tanınan ve tercih edilen bir klinik olmayı amaçlıyoruz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"> <b>Misyonumuz</b></h4>
                      <p className="description">
                      Boyun ağrısı, bel fıtığı, diz ağrısı gibi çeşitli rahatsızlıklarınızın tedavisinde uzman ekibimizle sizlere en iyi hizmeti sunmak için çalışıyoruz. Sporcu sağlığından omurga sağlığına, skolyozdan sırt ağrısına kadar geniş bir yelpazede sunduğumuz hizmetlerle, sektöre yön veren öncü bir kuruluş olarak dünya standartlarında sağlık hizmeti sunmayı misyon ediniyoruz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                      <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Hizmet Kalitemiz</b></h4>
                      <p className="description">
                      Ossa Clinic olarak, boyun düzleşmesi, bel fıtığı tedavisi, omurga eğriliği çözümleri gibi konularda en uygun ve etkili çözümleri sunarak sağlığınızı ön planda tutuyoruz. Uzmanlığımızı ve deneyimimizi bir araya getirerek, son teknolojiyi kullanarak kaliteli ve ekonomik sağlık hizmetleri sunmaktayız.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto order-2 mt-5 mt-md-0 order-md-1" data-aos="zoom-in" data-aos-duration="2000" md="5" style={{alignContent:"center"}}>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Uzman Kadro</b></h4>
                      <p className="description">
                      Ossa Clinic olarak, sadece fizyoterapi hizmeti sunmakla kalmayıp, aynı zamanda sağlıklı yaşam alanında tüm bilgi birikimimizle danışanlarımızın yanında olan, konusunda profesyonel bir ekibe sahibiz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Tedavi ve Takip</b></h4>
                      <p className="description">
                      Hastalarımızın tedavi süreçlerinin ve iyileşme süreçlerinin tam zamanında, istenilen kalitede ve kusursuz gerçekleşmesini sağlayan, tedavi yönetiminde en iyi fizyoterapi merkezi konumundayız.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons ">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Online Altyapı</b></h4>
                      <p className="description">
                      Fizyoterapi merkezi olarak, tedavi süreçlerini destekleyen kapsamlı bir IT altyapısı ile danışanlarımızın tedavi süreçlerini eş zamanlı olarak takip ediyor ve kalite kontrollerini gerçekleştiriyoruz.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto mt-0 mt-md-5 order-1 order-md-2" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/about-us/2.jpg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Ossa Clinic ile Tanışın</CardTitle>
                      <p className="card-description text-white">
                      En üst kalitede bir sağlık deneyimi sunuyoruz. Yenilikçi tedavi yöntemlerimiz ve modern teknolojilerimizle, danışanlarımızın ihtiyaçlarına özel çözümler üretiyoruz. Kişiye özel tedavi planları, detaylı değerlendirmeler ve sürekli takip ile her adımda yanınızdayız. Amacımız, sağlığınızı en iyi şekilde destekleyerek yaşam kalitenizi artırmak ve sizlere güvenli, etkili ve konforlu bir tedavi süreci sunmaktır.
                      </p>
                      <Badge className="badge-neutral">Ossa Clinic Tech &trade;</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="title">
              <br></br>
              
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274" ,fontFamily:"Benguiat Regular"}}
                data-aos="fade-right" data-aos-duration="1000"
              >
                <b>UZMAN EKİBİMİZ İLE TANIŞIN</b>
              </h2>
            </div>
            <br></br>
            <Row className="justify-content-center">
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/abdullah-okur" style={{border:"none"}} aria-label="Abdullah Okur">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/AbdullahOkar.png")}
                      ></img>
                     
                    </HashLink>
                  </div>
                  <CardBody>
                  
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/abdullah-okur"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Abdullah Okur" 
                      >
                        <b style={{fontWeight:"bolder"}}>ABDULLAH OKUR</b><br/>
                        <i>Uzman Fizyoterapist</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/ayca-albay" aria-label="Ayça Albay" style={{border:"none"}}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/AycaAlbay.png")}
                      ></img>
                     
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/ayca-albay"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Ayça Albay"
                      >
                        <b style={{fontWeight:"bolder"}}>AYÇA ALBAY</b><br/>
                        <i>Fizyoterapist</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/berkay-bugra-turan" style={{border:"none"}} aria-label="BERKAY BUĞRA TURAN">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/BerkayBugraTuran.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/berkay-bugra-turan"
                        style={{ color: "#123274", border:"none"}} aria-label="BERKAY BUĞRA TURAN"
                      >
                         <b style={{fontWeight:"bolder"}}>BERKAY BUĞRA TURAN</b><br/>
                        <i>Fizyoterapist</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/ilkenur-sari" style={{border:"none"}} aria-label="ilkenur sarı">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/IlkenurSari.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/ilkenur-sari"
                        style={{ color: "#123274", border:"none"}} aria-label="ilkenur sarı"
                      >
                         <b style={{fontWeight:"bolder"}}>İLKENUR SARI</b><br/>
                        <i>Fizyoterapist</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/busra-uzuner" style={{border:"none"}} aria-label="BÜŞRA UZUNER">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/BusraUzuner.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/busra-uzuner"
                        style={{ color: "#123274", border:"none"}} aria-label="BÜŞRA UZUNER"
                      >
                         <b style={{fontWeight:"bolder"}}>BÜŞRA UZUNER</b><br/>
                        <i>Diyetisyen</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/tugce-sengul-karahanci" style={{border:"none"}} aria-label="TUĞÇE ŞENGÜL KARAHANCI">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/TugceSengulKarahanci.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/tugce-sengul-karahanci"
                        style={{ color: "#123274", border:"none"}} aria-label="TUĞÇE ŞENGÜL KARAHANCI"
                      >
                         <b style={{fontWeight:"bolder"}}>TUĞÇE ŞENGÜL KARAHANCI</b><br/>
                        <i>Diyetisyen</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/gozde-nazik-icer" style={{border:"none"}} aria-label="GÖZDE NAZİK İÇER">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/GozdeNazikIcer.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/gozde-nazik-icer"
                        style={{ color: "#123274", border:"none"}} aria-label="GÖZDE NAZİK İÇER"
                      >
                         <b style={{fontWeight:"bolder"}}>GÖZDE NAZİK İÇER</b><br/>
                        <i>Uzman Psikolojik Danışman</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/hakkimizda/ece-ellialtioglu" style={{border:"none"}} aria-label="ECE ELLİALTIOĞLU">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/EceEllialtioglu.png")}
                      ></img>
                      
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/hakkimizda/ece-ellialtioglu"
                        style={{ color: "#123274", border:"none"}} aria-label="ECE ELLİALTIOĞLU"
                      >
                         <b style={{fontWeight:"bolder"}}>ECE ELLİALTIOĞLU</b><br/>
                        <i>Uzman Klinik Psikolog</i> 
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Container>
          </div>
    <Footer/>
    </div>
  )
}

export default AboutUs