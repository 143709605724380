import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect, useRef } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
// import imageHeader from "../assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/AndulasyonHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const BerkayBugraTuran = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);
  const headerRef = useRef(null);
  return (
    <>
      <Helmet>
        <title>BERKAY BUĞRA TURAN | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="OSSA CLINIC" data-rh="true" />
        <link rel="canonical" href="https://www.ossaclinic.com/" />
        <meta property="og:title" content="BERKAY BUĞRA TURAN | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com/" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      {/* <PictureHeader imageHeader={imageHeader} /> */}
      <div ref={headerRef}>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div style={{ height: "6rem" }}></div>
          <div>
            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BERKAY BUĞRA TURAN - FİZYOTERAPİST</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/BerkayBugraTuran.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>Kişisel Bilgiler</strong>
              </h3>

              <p>
                2023 yılında İstanbul Aydın Üniversitesi Fizyoterapi ve
                Rehabilitasyon Lisans programını bitirdi.
              </p>

              <br />

              <h3>
                <strong>Alınan Sertifikalar</strong>
              </h3>
              <ul>
                <li>DDMT</li>
                <li>Kinezyolojik Bantlama</li>
                <li>Kuru İğneleme</li>
              </ul>

              <br />

              <h3>
                <strong>Uzmanlık Alanları</strong>
              </h3>
              <p>
                Şu anda ortopedik hastalıkların rehabilitasyonu, nörolojik
                hastalıkların rehabilitasyonu, bel-boyun fıtıkları, fibromyalji,
                kireçlenme ve geriatrik hastalıkların rehabilitasyonu
                alanlarında hizmet vermektedir.
              </p>

              <br />

              <h3>
                <strong>Aktif Çalıştığı Merkez</strong>
              </h3>
              <p>
                OSSA Fizyoterapi ve Sağlıklı Yaşam Merkezinde aktif olarak
                çalışmaktadır.
              </p>
            </Col>
          </Row>

          <div className="team-2 pb-0 pt-0">
            <div className="title">
              <br></br>

              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>DİĞER UZMANLARIMIZ</b>
              </h2>
            </div>
            <br></br>
            <Row className="justify-content-center">
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/abdullah-okur"
                      style={{ border: "none" }}
                      aria-label="Abdullah Okur"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/AbdullahOkar.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/abdullah-okur"
                        style={{ color: "#123274", borderStyle: "none" }}
                        aria-label="Abdullah Okur"
                      >
                        <b style={{ fontWeight: "bolder" }}>ABDULLAH OKUR</b>
                        <br />
                        <i>Uzman Fizyoterapist</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/ayca-albay"
                      aria-label="Ayça Albay"
                      style={{ border: "none" }}
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/AycaAlbay.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/ayca-albay"
                        style={{ color: "#123274", borderStyle: "none" }}
                        aria-label="Ayça Albay"
                      >
                        <b style={{ fontWeight: "bolder" }}>AYÇA ALBAY</b>
                        <br />
                        <i>Fizyoterapist</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/ilkenur-sari"
                      style={{ border: "none" }}
                      aria-label="ilkenur sarı"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/IlkenurSari.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/ilkenur-sari"
                        style={{ color: "#123274", border: "none" }}
                        aria-label="ilkenur sarı"
                      >
                        <b style={{ fontWeight: "bolder" }}>İLKENUR SARI</b>
                        <br />
                        <i>Fizyoterapist</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/busra-uzuner"
                      style={{ border: "none" }}
                      aria-label="BÜŞRA UZUNER"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/BusraUzuner.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/busra-uzuner"
                        style={{ color: "#123274", border: "none" }}
                        aria-label="BÜŞRA UZUNER"
                      >
                        <b style={{ fontWeight: "bolder" }}>BÜŞRA UZUNER</b>
                        <br />
                        <i>Diyetisyen</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/tugce-sengul-karahanci"
                      style={{ border: "none" }}
                      aria-label="TUĞÇE ŞENGÜL KARAHANCI"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/TugceSengulKarahanci.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/tugce-sengul-karahanci"
                        style={{ color: "#123274", border: "none" }}
                        aria-label="TUĞÇE ŞENGÜL KARAHANCI"
                      >
                        <b style={{ fontWeight: "bolder" }}>
                          TUĞÇE ŞENGÜL KARAHANCI
                        </b>
                        <br />
                        <i>Diyetisyen</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/gozde-nazik-icer"
                      style={{ border: "none" }}
                      aria-label="GÖZDE NAZİK İÇER"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/GozdeNazikIcer.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/gozde-nazik-icer"
                        style={{ color: "#123274", border: "none" }}
                        aria-label="GÖZDE NAZİK İÇER"
                      >
                        <b style={{ fontWeight: "bolder" }}>GÖZDE NAZİK İÇER</b>
                        <br />
                        <i>Uzman Psikolojik Danışman</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Card className="card-plain card-blog" onClick={()=>headerRef.current.scrollIntoView()}>
                  <div
                    className="card-image"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <HashLink
                      to="/hakkimizda/ece-ellialtioglu"
                      style={{ border: "none" }}
                      aria-label="ECE ELLİALTIOĞLU"
                    >
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/ossaPictures/EceEllialtioglu.png")}
                      ></img>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle
                      tag="h1"
                      className="text-center"
                      style={{ fontSize: "1.4rem", fontWeight: "400" }}
                    >
                      <HashLink
                        to="/hakkimizda/ece-ellialtioglu"
                        style={{ color: "#123274", border: "none" }}
                        aria-label="ECE ELLİALTIOĞLU"
                      >
                        <b style={{ fontWeight: "bolder" }}>ECE ELLİALTIOĞLU</b>
                        <br />
                        <i>Uzman Klinik Psikolog</i>
                      </HashLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BerkayBugraTuran;
