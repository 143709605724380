import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// Stil dosyaları
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

// Sayfalar
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import FizikTedavi from "./pages/FizikTedavi";
import Beslenme from "./pages/Beslenme";
import Psikoloji from "./pages/Psikoloji";
import Blog from "./pages/Blog";
import Resimler from "./pages/Resimler";
import Videolar from "./pages/Videolar";

// PrimeReact
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// HelmetProvider
import { HelmetProvider } from "react-helmet-async";

// SizeProvider
import { SizeProvider } from "components/SizeControl/SizeControl";
import SirtAgrisi from "pages/SirtAgrisi";
import BoyunAgrisi from "pages/BoyunAgrisi";
import BelFitigi from "pages/BelFitigi";
import OmurgaSagligi from "pages/OmurgaSagligi";
import DizAgrisi from "pages/DizAgrisi";
import Skolyoz from "pages/Skolyoz";
import SporcuSagligi from "pages/SporcuSagligi";
import Redirect from "components/Redirect/Redirect";
import Osteopati from "pages/Osteopati";
import ManuelTerapi from "pages/ManuelTerapi";
import Recovery from "pages/Recovery";
import Rehabilitasyonlar from "pages/Rehabilitasyonlar";
import KlinikPlates from "pages/KlinikPlates";
import GebelikPlatesi from "pages/GebelikPlatesi";
import TeknolojikCihazlar from "pages/TeknolojikCihazlar";
import BackUpTeknolojisi from "pages/BackUpTeknolojisi";
import RobotikTaramaliLazer from "pages/RobotikTaramaliLazer";
import AndulasyonTerapi from "pages/AndulasyonTerapi";
import AbdullahOkur from "pages/AbdullahOkur";
import AycaAlbay from "pages/AycaAlbay";
import BerkayBugraTuran from "pages/BerkayBugraTuran";
import IlkenurSari from "pages/IlkenurSari";
import BusraUzuner from "pages/BusraUzuner";
import TugceSengulKarahanci from "pages/TugceSengulKarahanci";
import GozdeNazikIcer from "pages/GozdeNazikIcer";
import EceEllialtioglu from "pages/EceEllialtioglu";
import BlogRouter from "components/Blog/BlogRouter";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PrimeReactProvider>
    <SizeProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hakkimizda" element={<AboutUs />} />
            <Route path="/hakkimizda/abdullah-okur" element={<AbdullahOkur />} />
            <Route path="/hakkimizda/ayca-albay" element={<AycaAlbay />} />
            <Route path="/hakkimizda/berkay-bugra-turan" element={<BerkayBugraTuran />} />
            <Route path="/hakkimizda/ilkenur-sari" element={<IlkenurSari />} />
            <Route path="/hakkimizda/busra-uzuner" element={<BusraUzuner />} />
            <Route path="/hakkimizda/tugce-sengul-karahanci" element={<TugceSengulKarahanci />} />
            <Route path="/hakkimizda/gozde-nazik-icer" element={<GozdeNazikIcer />} />
            <Route path="/hakkimizda/ece-ellialtioglu" element={<EceEllialtioglu />} />
            
            
            <Route path="/iletisim" element={<ContactUs />} />
            <Route path="/fizik-tedavi-ve-rehabilitasyon" element={<FizikTedavi/>} />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi"
              element={<SirtAgrisi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
              element={<BoyunAgrisi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/bel-fitigi"
              element={<BelFitigi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
              element={<OmurgaSagligi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/diz-agrisi"
              element={<DizAgrisi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/skolyoz"
              element={<Skolyoz/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
              element={<SporcuSagligi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/osteopati"
              element={<Osteopati/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/manuel-terapi"
              element={<ManuelTerapi/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/recovery"
              element={<Recovery/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar"
              element={<Rehabilitasyonlar/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/klinik-plates"
              element={<KlinikPlates/>}
            />
            <Route
              path="/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi"
              element={<GebelikPlatesi/>}
            />
            
            <Route path="/beslenme-ve-diyetetik" element={<Beslenme />} />
            <Route path="/psikolojik-danismanlik" element={<Psikoloji />} />
            <Route
              path="/teknolojik-cihazlar-ile-destek"
              element={<TeknolojikCihazlar/>}
            />
            <Route
              path="/teknolojik-cihazlar-ile-destek/back-up-teknolojisi"
              element={<BackUpTeknolojisi/>}
            />
            <Route
              path="/teknolojik-cihazlar-ile-destek/robotik-taramali-lazer"
              element={<RobotikTaramaliLazer/>}
            />
            <Route
              path="/teknolojik-cihazlar-ile-destek/andulasyon-terapi"
              element={<AndulasyonTerapi/>}
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/*" element={<BlogRouter/>}/>
            <Route path="/resimler" element={<Resimler />} />
            <Route path="/videolar" element={<Videolar />} />
            <Route path="/redirect-doktorlartakvimi" element={<Redirect />} />

            {/* 404 yönlendirme */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </SizeProvider>
  </PrimeReactProvider>
);
